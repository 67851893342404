import { Action } from "@ngrx/store";
import { Update } from '@ngrx/entity';

import { main01featureUI } from '../../models/main01featureUI.model';

export enum main01featureActionTypes {
  MAIN01FEATUREADDONERECORD = '[main01feature] Add one Record',
  MAIN01FEATUREUPDATEONERECORD = '[main01feature] Update one Record',  
  MAIN01FEATUREDELETEONERECORD = '[main01feature] Delete one Record', 
  MAIN01FEATUREPAGEREQUESTED = '[main01feature] Page Requested', 
  MAIN01FEATUREPAGELOADED = '[main01feature] Page Loaded From DB', 
  MAIN01FEATUREPAGECANCELLED = '[main01feature] Page Request Cancelled', 
  MAIN01FEATUREUPSERTONERECORD = '[main01feature] Upsert one Record',
  MAIN01FEATURESETACTIVERECORD = '[main01feature] Set active Record',  
  MAIN01FEATURESETEDITRECORD = '[main01feature] Set edit Record', 
  MAIN01FEATUREUPDATECOUNT = '[main01feature] Update record Count', 
  MAIN01FEATURESAVEPAGINATIONVAL = '[main01feature] Save PageIndex, etc for pagination', 
}

export interface PageQuery {
  pageIndex: number;
  pageSize:number;
}

export class main01featurePageRequested implements Action {
  readonly type = main01featureActionTypes.MAIN01FEATUREPAGEREQUESTED;
  constructor(public payload: {parentId:string, page:PageQuery}) {
  }
}

export class main01featureSavePaginationVal implements Action {
  readonly type = main01featureActionTypes.MAIN01FEATURESAVEPAGINATIONVAL;
  constructor(public payload: {page:PageQuery}) {
  }
}

export class main01featurePageLoaded implements Action {
  readonly type = main01featureActionTypes.MAIN01FEATUREPAGELOADED;
  constructor(public payload: {main01featureUI_array: main01featureUI[]}) {
  }
}

export class main01featurePageCancelled implements Action {
  readonly type = main01featureActionTypes.MAIN01FEATUREPAGECANCELLED;
  constructor(public payload: {parentId:string, page:PageQuery}) {
  }
}

export class main01featureAddOneRecord implements Action {
  readonly type = main01featureActionTypes.MAIN01FEATUREADDONERECORD;
  constructor(public payload: {main01featureUI: main01featureUI}) {
  }
}

export class main01featureUpdateOneRecord implements Action {
  readonly type = main01featureActionTypes.MAIN01FEATUREUPDATEONERECORD;
  constructor(public payload: {main01featureUI: Update<main01featureUI>}) {
  }
}

export class main01featureDeleteOneRecord implements Action {
  readonly type = main01featureActionTypes.MAIN01FEATUREDELETEONERECORD;
  constructor(public payload: {id: string}) {
  }
}

export class main01featureUpsertOneRecord implements Action {
  readonly type = main01featureActionTypes.MAIN01FEATUREUPSERTONERECORD;
  constructor(public payload: {main01featureUI: main01featureUI}) {
  }
}
  
export class main01featureSetActiveRecord implements Action {
  readonly type = main01featureActionTypes. MAIN01FEATURESETACTIVERECORD;
   constructor(public payload: {id: string}) {
  }
}

export class main01featureSetEditRecord implements Action {
  readonly type = main01featureActionTypes. MAIN01FEATURESETEDITRECORD;
   constructor(public payload: {id: string}) {
  }
}

export class main01featureUpdateCount implements Action {
  readonly type = main01featureActionTypes.MAIN01FEATUREUPDATECOUNT;
   constructor(public payload: {count: number}) {
  }
}

// Union the valid types
export type main01featureActions = 
    main01featureAddOneRecord
  | main01featureUpdateOneRecord  
  | main01featureDeleteOneRecord
  | main01featurePageRequested
  | main01featurePageLoaded
  | main01featurePageCancelled  
  | main01featureUpsertOneRecord
  | main01featureSetActiveRecord
  | main01featureSetEditRecord  
  | main01featureUpdateCount
  | main01featureSavePaginationVal
  
