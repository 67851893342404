import {Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import {main01featureUI} from "../models/main01featureUI.model";
import { DBisFirestore } from './db.is.firestore.services';
import { select, Store } from "@ngrx/store";
import { AppState } from '../store/reducers';
import { catchError, finalize, tap, first, map} from 'rxjs/operators';

//import { main01featureLastDatabaseRecordVisible } from '../store/selectors/main01feature.selectors';
import { UIService } from './ui.services';

@Injectable()
export class main01featureService {

constructor(private http:HttpClient, public dbisFirestore: DBisFirestore, private store: Store<AppState>, public uiService: UIService) {}
    
findAllmain01feature(parentId:string, pageNumber = 0, pageSize = 3): Observable<main01featureUI[]> {
  
if (!this.uiService.main01featureTheLastDatabaseRecordVisible) {
    this.uiService.main01featureTheLastDatabaseRecordVisible = '-999999999999999999999999999999'
}

return this.dbisFirestore.db
                    .collection('main01featureDB', ref => ref.orderBy('lastupdated').startAfter(this.uiService.main01featureTheLastDatabaseRecordVisible).limit(3))
                    //.collection('main01featureDB', ref => ref.where("parentid", "==", parentId).orderBy('lastupdated').startAfter(this.uiService.main01featureTheLastDatabaseRecordVisible).limit(3))
                    //.collection('main01featureDB', ref => ref.where("parentid", "==", parentId))
                    .snapshotChanges()
                    .pipe(map(([...docArray]) => {  // solves - ngrx map does not exists on {} issue
                    // throw(new Error());
                    return (docArray as any[]).map(doc => { 
                    //return docArray.map(doc => {                      
                     const main01featureUI_row = {} as main01featureUI;
                     main01featureUI_row.id = doc.payload.doc.id;
                     main01featureUI_row.parentid = doc.payload.doc.data()["parentid"];
                     main01featureUI_row.title =  doc.payload.doc.data()["title"];
                     main01featureUI_row.extra = doc.payload.doc.data()["extra"];
                     main01featureUI_row.deleted = doc.payload.doc.data()["deleted"];  
                     main01featureUI_row.lastupdated = doc.payload.doc.data()["lastupdated"]; 
                     this.uiService.main01featureTheLastDatabaseRecordVisible = doc.payload.doc; 
                    return main01featureUI_row
                             });
                      }))
                   //    .subscribe((data)=> {return data});
     }

}
