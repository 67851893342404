import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { StoreModule } from '@ngrx/store';
import { RouterStateSerializer, StoreRouterConnectingModule } from "@ngrx/router-store";
import { CustomSerializer } from '../shared/utils';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";

import * as fromAllReducers from "../store/reducers"
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { storeFreeze } from "ngrx-store-freeze";
import { metaReducers }  from '../store/reducers';
import { AuthEffects } from '../store/effects/auth.effects';

import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestore} from 'angularfire2/firestore';
import { environment } from '../environments/environment';
import { UIService } from '../services/ui.services';
import { main01featureDataSource } from '../services/main01feature.datasource';
import { main02featureDataSource } from '../services/main02feature.datasource';
import { main01featureService } from '../services/main01feature.service';
import { main02featureService } from '../services/main02feature.service';
import { DBisFirestore } from '../services/db.is.firestore.services';

import { ModalshellPageModule } from "./modalshell/modalshell.module";
import { ModalshellPage } from "./modalshell/modalshell.page";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import {DefaultUrlSerializer, UrlSerializer, UrlTree} from '@angular/router';

import {
    MatDatepickerModule,
    MatDialogModule, MatInputModule, MatPaginatorModule, MatProgressSpinnerModule, MatSelectModule,
    MatSlideToggleModule,
    MatSortModule, MatTableModule
} from "@angular/material";

import {MatTabsModule} from "@angular/material/tabs";
import {MatMenuModule} from "@angular/material/menu";
import {MatCardModule} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";

export class MyUrlSerializer extends DefaultUrlSerializer implements UrlSerializer  {
  /** Converts a `UrlTree` into a url - see https://github.com/angular/angular/issues/24656*/
  serialize(tree: UrlTree): string {

    // Option 1 - remove every thing after the '(' in URL - get rid of secondary outlet say 'modal' completely) - better
    //Works good - no secondary outlets routes displayed on URL URI -  outlet: 'modal' is not shown
    //www.example.com/(modal:signin) - replace by   www.example.com/ using super.serialize(tree).trim().split('(')[0] 
    //OR  www.example.com/main01feature(modal:signin) - replace by www.example.com/main01feature
    var main_route = super.serialize(tree).trim().split('(')[0]
    return main_route;
    
    // Option 2 - remove /(' from url and replace by /home or '' */
    // if (super.serialize(tree).trim().substr(0,2) == '/(') {
    //   return '';
    // }
    // return super.serialize(tree);

  }
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [/*SignupPage,*/ ModalshellPage],
  imports: [
    MatButtonModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSelectModule,
    MatDatepickerModule,
    //MatMomentDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot(fromAllReducers.reducers, { metaReducers }),
    StoreDevtoolsModule.instrument(), // !environment.production ? StoreDevtoolsModule.instrument() : []  // add this later, so it does not show on prod
    StoreRouterConnectingModule.forRoot({stateKey: 'router'}),
    AngularFireModule.initializeApp(environment.firebase),
    EffectsModule.forFeature([AuthEffects]),
    //SignupPageModule,  //since using Lazy Loading, do not include it here
    ModalshellPageModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    StoreModule,
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AngularFireAuth,
    AngularFirestore,
    UIService,
    main01featureDataSource,
    main01featureService,
    main02featureDataSource,
    main02featureService,    
    DBisFirestore,
    { provide: UrlSerializer, useClass: MyUrlSerializer }
  ],
  exports: [
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSelectModule,
    MatDatepickerModule,
    MatDatepickerModule,
    MatDialogModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
