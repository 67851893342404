import { AuthData } from "../../models/authdata.model";
import {AuthActions, AuthActionTypes} from "../actions/auth.action";

export interface AuthState extends AuthData {uid: string, emailVerified: boolean, token: string, firstName: string, lastName: string, phone: string}   // you can extend more than 1 interface/fields if you need more say non-db fields on the AuthState

const initialState: AuthState = {
  uid: '',
  email: '',
  password: '',
  displayName: '',
  photoURL: '',
  emailVerified: false,
  token: '',
  firstName: '',
  lastName: '',
  phone: ''
  //products:[] 
};

export function AuthReducer(state = initialState, action:AuthActions):AuthState {
    //console.log(' action.type = ', action.type, ' action = ', action);
  switch (action.type) {
    case AuthActionTypes.SIGNIN :    // Note the Action type SIGNIN is captured by the Effects, for database, the result is SIGNINSUCCESS or SIGNINFAIL that is processed here
     { //console.log("action.type = ", action.type);
      return {
          ...state,
          ...action.payload,
          password: ''   // no need to store password in the store          
      } }
      // state.email = 'INCORRECT WAY';  //updates store/state but that is the worst mistake in ngrx redux - state is unmutable
      // return state
    case AuthActionTypes.SIGNINSUCCESS :    // Note the Action type SIGNIN is captured by the Effects, for database, the result is SIGNINSUCCESS or SIGNINFAIL that is processed here
      {//console.log("action.type = ", action.type);
      return {
          ...state,
          ...action.payload,
          password: ''   // no need to store password in the store
      }}
      // state.email = 'INCORRECT WAY';  //updates store/state but that is the worst mistake in ngrx redux - state is unmutable
      // return state
    case AuthActionTypes.SIGNINFAIL :
      {//console.log("reducer SIGNFAIL");
      return {
          ...state,
          ...action.payload,
          password: ''   
      }}
   case AuthActionTypes.SIGNUP :
      {//console.log("action.type = ", action.type);
      return {
          ...state,
          ...action.payload,
          password: ''   // no need to store password in the store
      }}
   case AuthActionTypes.SIGNUPSUCCESS :
      {//console.log("action.type = ", action.type);
      return {
          ...state,
          ...action.payload,
          password: ''   // no need to store password in the store          
      }}
   case AuthActionTypes.SIGNUPFAIL :
      {//console.log("action.type = ", action.type);
      return {
          ...state,
          ...action.payload,
          password: ''            
      }}      
    case AuthActionTypes.SIGNOUT :
      {//console.log("action.type = ", action.type);
      return {
          ...state,
          ...action.payload
      }}      
    case AuthActionTypes.SIGNOUTSUCCESS :
      {//console.log("action.type = ", action.type);
      return {
          ...state,
          ...action.payload
      }}         
   case AuthActionTypes.CHANGEPASSWORD :
      {//console.log("action.type = ", action.type);
      return {
          ...state,
          //...action.payload  - do not update anything on the store for password update.
      }}
   case AuthActionTypes.FORGOTPASSWORD :
      {//console.log("action.type = ", action.type);
      return {
          ...state,
          //...action.payload - do not update anything on the store.
      }}
   case AuthActionTypes.VERIFYACCOUNT :
      {//console.log("action.type = ", action.type);
      return {
          ...state,
          //...action.payload // no payload
      }}
   case AuthActionTypes.VERIFYACCOUNTSUCCESS :
      {//console.log("action.type = ", action.type);
      return {
          ...state,
          ...action.payload
        //  emailVerified: true,
          //...action.payload
      }}      
   case AuthActionTypes.UPDATEPROFILE :
      {//console.log("action.type = ", action.type);
      return {
          ...state,   //do not change the state here, when successful in effects, the udpate UPDATEPROFILESUCCESS is dispatched to update
          //...action.payload
      }}
   case AuthActionTypes.UPDATEPROFILESUCCESS :
      {//console.log("action.type = ", action.type);
      return {
          ...state,
          displayName: action.payload.displayName,
          photoURL: action.payload.photoURL,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          phone: action.payload.phone          
      }}  
   case AuthActionTypes.CHECKIFLOGGEDON :
      {//console.log("action.type = ", action.type);
      return {
          ...state
      }}  
   case AuthActionTypes.CHECKIFLOGGEDONTRUE :
      {//console.log("action.type = ", action.type);
      return {
          ...state,
          uid: action.payload.uid,
          email: action.payload.email,
          displayName: action.payload.displayName,
          photoURL: action.payload.photoURL,
          emailVerified: action.payload.emailVerified,
          token: action.payload.token,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          phone: action.payload.phone              
      }}  
   case AuthActionTypes.CHECKIFLOGGEDONFALSE :
      {//console.log("action.type = ", action.type);
      return {
          ...state,
          uid: '',
          email: '',
          displayName: '',
          photoURL: '',
          emailVerified: false,
          token: '',
          firstName: '',
          lastName: '',
          phone: ''             
      }}        
    default :
    //console.log("action.type = ", action.type);
    {//console.log('In default of auth reducer');    
      return state
  }}
}