import {createSelector} from '@ngrx/store';

export const selectAuthState = state => state.auth;

export const getemail = createSelector(
  selectAuthState,
  auth => auth.email
);

export const getauthall = createSelector(
  selectAuthState,
  auth => auth
);

export const getdisplayName = createSelector(
  selectAuthState,
  auth => auth.displayName
);

export const getcombinedName = createSelector(
  selectAuthState,
  auth => auth.firstName + ' ' + auth.lastName
);

export const getGreetings = createSelector(
  selectAuthState,
  auth => (auth.email == null || auth.email == ' ' || auth.email == '')? 'Hello!' : ((auth.displayName == null || auth.displayName == '' || auth.displayName == ' ')?  'Hello ' + auth.email + '!' : 'Hello ' + auth.displayName + '!') 
);

export const getIsLoggedIn = createSelector(
  selectAuthState,
  auth => { return (auth.email == null || auth.email == '' || auth.email == ' ')? false : true; }
);

export const getEmailVerified = createSelector(
  selectAuthState,
  auth => { return auth.emailVerified}
);

