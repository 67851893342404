import { Action } from "@ngrx/store";
import { Update } from '@ngrx/entity';

import { main02featureUI } from '../../models/main02featureUI.model';

export enum main02featureActionTypes {
  MAIN02FEATUREADDONERECORD = '[main02feature] Add one Record',
  MAIN02FEATUREUPDATEONERECORD = '[main02feature] Update one Record',  
  MAIN02FEATUREDELETEONERECORD = '[main02feature] Delete one Record', 
  MAIN02FEATUREPAGEREQUESTED = '[main02feature] Page Requested', 
  MAIN02FEATUREPAGELOADED = '[main02feature] Page Loaded From DB', 
  MAIN02FEATUREPAGECANCELLED = '[main02feature] Page Request Cancelled', 
  MAIN02FEATUREUPSERTONERECORD = '[main02feature] Upsert one Record',
  MAIN02FEATURESETACTIVERECORD = '[main02feature] Set active Record',  
  MAIN02FEATURESETEDITRECORD = '[main02feature] Set edit Record', 
  MAIN02FEATUREUPDATECOUNT = '[main02feature] Update record Count', 
  MAIN02FEATURESAVEPAGINATIONVAL = '[main02feature] Save PageIndex, etc for pagination', 
}

export interface PageQuery {
  pageIndex: number;
  pageSize:number;
}

export class main02featurePageRequested implements Action {
  readonly type = main02featureActionTypes.MAIN02FEATUREPAGEREQUESTED;
  constructor(public payload: {parentId:string, page:PageQuery}) {
  }
}

export class main02featureSavePaginationVal implements Action {
  readonly type = main02featureActionTypes.MAIN02FEATURESAVEPAGINATIONVAL;
  constructor(public payload: {page:PageQuery}) {
  }
}

export class main02featurePageLoaded implements Action {
  readonly type = main02featureActionTypes.MAIN02FEATUREPAGELOADED;
  constructor(public payload: {main02featureUI_array: main02featureUI[]}) {
  }
}

export class main02featurePageCancelled implements Action {
  readonly type = main02featureActionTypes.MAIN02FEATUREPAGECANCELLED;
  constructor(public payload: {parentId:string, page:PageQuery}) {
  }
}

export class main02featureAddOneRecord implements Action {
  readonly type = main02featureActionTypes.MAIN02FEATUREADDONERECORD;
  constructor(public payload: {main02featureUI: main02featureUI}) {
  }
}

export class main02featureUpdateOneRecord implements Action {
  readonly type = main02featureActionTypes.MAIN02FEATUREUPDATEONERECORD;
  constructor(public payload: {main02featureUI: Update<main02featureUI>}) {
  }
}

export class main02featureDeleteOneRecord implements Action {
  readonly type = main02featureActionTypes.MAIN02FEATUREDELETEONERECORD;
  constructor(public payload: {id: string}) {
  }
}

export class main02featureUpsertOneRecord implements Action {
  readonly type = main02featureActionTypes.MAIN02FEATUREUPSERTONERECORD;
  constructor(public payload: {main02featureUI: main02featureUI}) {
  }
}
  
export class main02featureSetActiveRecord implements Action {
  readonly type = main02featureActionTypes. MAIN02FEATURESETACTIVERECORD;
   constructor(public payload: {id: string}) {
  }
}

export class main02featureSetEditRecord implements Action {
  readonly type = main02featureActionTypes. MAIN02FEATURESETEDITRECORD;
   constructor(public payload: {id: string}) {
  }
}

export class main02featureUpdateCount implements Action {
  readonly type = main02featureActionTypes.MAIN02FEATUREUPDATECOUNT;
   constructor(public payload: {count: number}) {
  }
}

// Union the valid types
export type main02featureActions = 
    main02featureAddOneRecord
  | main02featureUpdateOneRecord  
  | main02featureDeleteOneRecord
  | main02featurePageRequested
  | main02featurePageLoaded
  | main02featurePageCancelled  
  | main02featureUpsertOneRecord
  | main02featureSetActiveRecord
  | main02featureSetEditRecord  
  | main02featureUpdateCount
  | main02featureSavePaginationVal
  
