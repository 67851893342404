import { Action } from "@ngrx/store";

import * as fromHouseKeepingReducer from "../reducers/housekeeping.reducer"

export enum HouseKeepingActionTypes {
  ToggleSomesampleflag = '[HouseKeeping] Toggle Menu'
}

export class ToggleSomesampleflag implements Action {
  readonly type = HouseKeepingActionTypes.ToggleSomesampleflag;
  constructor(public payload: fromHouseKeepingReducer.HouseKeepingState) {
  }
}

// Union the valid types
export type HouseKeepingActions = ToggleSomesampleflag

   