import { Injectable} from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Observable, Subject } from "rxjs";
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs/operators';

import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';

import { getemail, getcombinedName, getGreetings, getIsLoggedIn, getEmailVerified } from '../store/selectors/auth.selectors';
import { getsomesampleflag } from '../store/selectors/housekeeping.selectors';

//import { mainmasterfeatureDB } from '../models/mainmasterfeatureDB.model';

import { ModalController } from '@ionic/angular';
import { DBisFirestore } from './db.is.firestore.services';

import {AppState} from '../store/reducers';

import { main01featureDataSource } from './main01feature.datasource';
import { main02featureDataSource } from './main02feature.datasource';
import { main03featureDataSource } from './main03feature.datasource';

@Injectable()
export class UIService {

  public housekeeping_somesampleflag$: Observable<boolean>;
  public auth_getemail$: Observable<string>;  
  public auth_getcombinedName$: Observable<string>;  
  public auth_getGreetings$: Observable<string>;
  public auth_getIsLoggedIn$: Observable<boolean>;  
  public auth_getEmailVerified$: Observable<boolean>;  
  public modalCloseNow = new Subject<boolean>();  
  public main01featureTheLastDatabaseRecordVisible: string; 
  public main02featureTheLastDatabaseRecordVisible: string;   
  public main03featureTheLastDatabaseRecordVisible: string;   
  
  //public dataSourceMainmasterfeature: any;   // declare it here so it can be accessed from anywhere even the effects and the template html for [dataSource].

constructor(
     public toastController: ToastController,
     private store: Store<AppState>,
     private modalCtrl: ModalController,
     public dbisFirestore: DBisFirestore,
     public main01featureDataSource: main01featureDataSource,
     public main02featureDataSource: main02featureDataSource,     
     public main03featureDataSource: main03featureDataSource,          
     ) {
        this.auth_getemail$ = this.store
        .pipe(
          select(getemail)
        );

      // leave this as example
      this.auth_getcombinedName$ = this.store
        .pipe(
          select(getcombinedName)
        );
        this.auth_getcombinedName$.subscribe( (data) => {
        }) 

      this.housekeeping_somesampleflag$ = this.store
        .pipe(
          select(getsomesampleflag)
        );
        
      // all these were moved here because it easy to access the ui.Service service.
      this.auth_getGreetings$ = this.store
        .pipe(
          select(getGreetings)
        );
        
      this.auth_getEmailVerified$ = this.store
        .pipe(
          select(getEmailVerified)
        );
        
      this.auth_getIsLoggedIn$ = this.store
        .pipe(
          select(getIsLoggedIn)
        );
        
        // this.auth_getIsLoggedIn$.subscribe( (data) => {
        //   //console.log("auth_getIsLoggedIn$ data = ", data);
        // }) 
        
        // Avoid this subscribe most or all the time, use async in the template directly, less clutter in ts - leave this commented here
        //this.auth_getcombinedName$.subscribe( (data) => {
        //}) 
     }

Generic_CreateNextUniqueId() {
  return this.dbisFirestore.Generic_CreateNextUniqueId();
}

async usetoast(message, duration) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position:  'bottom', //'middle' 'top'
    });
    toast.present();
}

async usetoastwithok(message, duration) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      showCloseButton: true,
      position:  'bottom', //'middle' 'top'
      closeButtonText: 'Ok, got it'      
    });
    toast.present();
}

// https://stackoverflow.com/questions/48125881/how-to-open-a-component-from-lazy-loaded-module-in-ngbmodal
async onModal(page: any, finalPage: any, pageOnReturn: any) {
  let tModal = await this.modalCtrl.create({component: page, componentProps: {showBackdrop: false, enableBackdropDismiss: false, finalPage: finalPage, pageOnReturn: pageOnReturn}});
  await tModal.present(); 
  return true;
  }  
  
}
