import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import { NavController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

import * as fromAuthAction from "../store/actions/auth.action";
import { AppState } from '../store/reducers';
import { UIService } from "../services/ui.services";

import { ModalshellPage } from './modalshell/modalshell.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  
  modalshellPage = ModalshellPage;  
  
  constructor(
    private router:Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private modalCtrl: ModalController,    
    private store: Store<AppState>,
    public navCtrl: NavController,
    public uiService: UIService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
    this.statusBar.styleDefault();
    this.splashScreen.hide();
        
    });
 
  //check if user is already logged on.
  //this.afAuth.auth.currentUser.reload(); // leave this here, even if commented
  this.store.dispatch(new fromAuthAction.Checkifloggedon());
  }

  // worked? leave it here
  // async onModal(page: any) {
  // //  this.nav.setRoot(page);
  // let profileModal = await this.modalCtrl.create({component: page, componentProps: {enableBackdropDismiss: false}});
  // return await profileModal.present();    
  // //this.menuCtrl.close();
  // }  
  
onLogout(e)  {
   setTimeout(() => {
     this.store.dispatch(new fromAuthAction.Signout({email: ''})); 
    }, 1);
}  

show_uri() {
    console.log('this router url = ', this.router.url);
}

pushPage() {
  console.log("In Push Page");
  //this.navCtrl.push('SecondPage');
}

}
