import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { main01featureActionTypes, main01featureActions } from "../actions/main01feature.action";
import { main01featureUI } from '../../models/main01featureUI.model';

export interface main01featureState extends EntityState<main01featureUI> {
  allmain01featureLoaded:boolean;
  main01featureCount:number;
  currentactiverow: string;
  currenteditrow: string;
  loading:boolean;
}

export const adapter : EntityAdapter<main01featureUI> =
  createEntityAdapter<main01featureUI>(/*{sortComparer: sortByCourseAndSeqNo}*/);

export const initialmain01featureState: main01featureState = adapter.getInitialState({
  allmain01featureLoaded: false,
  main01featureCount: 0,
  currentactiverow: undefined,
  currenteditrow: undefined,
  loading:false,
});

export function main01featureReducer(state = initialmain01featureState, action:main01featureActions):main01featureState {
  switch (action.type) {
    case main01featureActionTypes.MAIN01FEATUREADDONERECORD : 
      return adapter.addOne(action.payload.main01featureUI, state); 
    case main01featureActionTypes.MAIN01FEATUREUPSERTONERECORD :   
      return adapter.upsertOne(action.payload.main01featureUI, state); 
    case main01featureActionTypes.MAIN01FEATUREUPDATEONERECORD :   
      return adapter.updateOne(action.payload.main01featureUI, state);     
    case main01featureActionTypes.MAIN01FEATUREDELETEONERECORD :   
      return adapter.removeOne(action.payload.id, state);     
    case main01featureActionTypes.MAIN01FEATUREDELETEONERECORD :   
      return {
        ...state
      } 
    case main01featureActionTypes.MAIN01FEATUREPAGEREQUESTED :   
      return {
        ...state
      }
    case main01featureActionTypes.MAIN01FEATUREPAGELOADED :   
      {
      return adapter.addMany(action.payload.main01featureUI_array, {...state, loading:false });
      }
    case main01featureActionTypes.MAIN01FEATURESETACTIVERECORD :   
      return {
        ...state,
        currentactiverow: action.payload.id
      }   
    case main01featureActionTypes.MAIN01FEATURESETEDITRECORD :   
      return {
        ...state,
        currenteditrow: action.payload.id
      }       
    case main01featureActionTypes.MAIN01FEATUREUPDATECOUNT :   
      return {
        ...state,
        main01featureCount: action.payload.count
      } 
    default :
      return state
  }

}

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal

} = adapter.getSelectors();

