import {
  StoreModule,
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from "@ngrx/store";
import {
  StoreRouterConnectingModule,
  routerReducer,
  RouterReducerState,
  RouterStateSerializer
} from "@ngrx/router-store";


import { storeFreeze } from "ngrx-store-freeze";

//For Lazy Loading of store/state, remove (comment out) and move it to the module.ts of that component/page
import { environment } from "../../environments/environment";
import * as fromAuth from "./auth.reducer";
//import * as fromChat from "./chat.reducer"; //Remove (comment out) this for Lazy Loading of store/state
import * as fromHouseKeeping from "./housekeeping.reducer"; 
import * as FROMmain01featureREDUCER from "./main01feature.reducer"; 
import * as FROMmain02featureREDUCER from "./main02feature.reducer"; 
import * as FROMmain03featureREDUCER from "./main03feature.reducer"; 

import { Params, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";

export interface AppState {
  // auth: fromAuth.AuthState;
  // //chat: fromChat.ChatState;   //Remove this for Lazy Loading of store/state
  //   housekeeping: fromHouseKeeping.HouseKeepingState;
  //   router: RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<AppState> = {
  //For Lazy Loading of store/state, remove (comment out) and move it to the module.ts of that component/page
  auth: fromAuth.AuthReducer,
  housekeeping: fromHouseKeeping.HouseKeepingReducer,
  main01feature: FROMmain01featureREDUCER.main01featureReducer,  
  main02feature: FROMmain02featureREDUCER.main02featureReducer,  
  main03feature: FROMmain03featureREDUCER.main03featureReducer,    
  
//  chat: fromChat.ChatReducer //For Lazy Loading of store/state, remove (comment out) and move it to the module.ts of that component/page
  router: routerReducer
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [storeFreeze]
  : [];
  
export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}