import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../services/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'home', loadChildren: './home/home.module#HomePageModule'},
  { path: 'main01feature', loadChildren: '../pages/main01feature/main01feature.module#main01featurePageModule'},
  { path: 'main02feature', loadChildren: '../pages/main02feature/main02feature.module#main02featurePageModule'},  
  { path: 'nostorepagination', loadChildren: '../pages/nostorepagination/nostorepagination.module#NostorepaginationPageModule' },
  { path: 'landingpage', loadChildren: '../pages/landingpage/landingpage.module#LandingpagePageModule' },
  { path: 'nostorecustompagination', loadChildren: '../pages/nostorecustompagination/nostorecustompagination.module#NostorecustompaginationPageModule' },  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {}
