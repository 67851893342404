import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Routes, RouterModule, Router,  ActivatedRoute, RouterOutlet } from '@angular/router';
import { NavParams, ModalController } from '@ionic/angular';
import { UIService } from "../../services/ui.services";
import { map } from 'rxjs/internal/operators';

@Component({
  selector: 'app-modalshell',
  templateUrl: 'modalshell.page.html',
  styleUrls: ['modalshell.page.scss']
})
export class ModalshellPage implements OnInit, OnDestroy {

@ViewChild(RouterOutlet)
    public routerOutlet: RouterOutlet;
    
  public modalOutlet: any;
  public modalPageLabel: string;
  public currentPage: string;
  public pageOnReturn: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public uiService: UIService,
    public navParams: NavParams,
    public modalCtrl: ModalController) {
  }

  ngOnInit() {

    const finalPage: string = this.navParams.get('finalPage');
    this.pageOnReturn = this.navParams.get('pageOnReturn');
    //console.log( " finalPage = ", finalPage);
    
    this.currentPage = finalPage;
    switch(finalPage) {
    case 'signin': {
     this.goSignin();
     break; 
    }
    case 'signup': {
     this.goSignup();
     break; 
    }
    case 'forgotpassword': {
     this.goForgotpassword();
     break; 
    }
    case 'changepassword': {
     this.modalPageLabel = 'Change Password';      
     this.router.navigateByUrl(`(modal:changepassword)`, {relativeTo: this.route.parent, skipLocationChange: false,});
     break; 
    }
    case 'verifyaccount': {
     this.modalPageLabel = 'Verify Account Email';            
     this.router.navigateByUrl(`(modal:verifyaccount)`, {relativeTo: this.route.parent, skipLocationChange: false});
     break; 
    }
    case 'updateprofile': {
    //  this.modalPageLabel = 'Update Profile';            
    //  this.router.navigateByUrl(`(modal:updateprofile)`, {relativeTo: this.route.parent, skipLocationChange: false});
     this.goUpdateProfile();
     break; 
    }    
    }
  this.uiService.modalCloseNow.subscribe(data=>{ 
  this.onModalclose();
});

}

goSignin() {
   this.currentPage = 'signin';
   this.modalPageLabel = 'Sign In';
   this.router.navigateByUrl(`(modal:signin)`, {relativeTo: this.route.parent, skipLocationChange: false});
}
  
goSignup() {
   this.currentPage = 'signup';
   this.modalPageLabel = 'Sign Up';
   this.router.navigateByUrl(`(modal:signup)`, {relativeTo: this.route.parent, skipLocationChange: false});
}

goForgotpassword() {
   this.currentPage = 'forgotpassword';      
   this.modalPageLabel = 'Forgot Password';
   this.router.navigateByUrl(`(modal:forgotpassword)`, {relativeTo: this.route.parent, skipLocationChange: false});
}

goUpdateProfile() {
   this.currentPage = 'updateprofile';      
   this.modalPageLabel = 'Update Profile';            
   this.router.navigateByUrl(`(modal:updateprofile)`, {relativeTo: this.route.parent, skipLocationChange: false});
}    
  
ngOnDestroy() {

}

getLoginInfoTEST() {
  this.uiService.auth_getIsLoggedIn$.subscribe( (data) => {
        //  console.log("auth_getIsLoggedIn$ data = ", data);
        }) 
}

onModalclose() {
   this.modalCtrl.dismiss();
}

}
