import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { ModalshellPage } from './modalshell.page';

// import { SigninPage } from "../../pages/auth/signin/signin.page";
// import { SigninPageModule } from "../../pages/auth/signin/signin.module";

// import { SignupPageModule } from "../../pages/auth/signup/signup.module";
// import { SignupPage } from "../../pages/auth/signup/signup.page";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  //  ModalshellPage, 
    RouterModule.forChild([
      // {
      //   path: '',
      //   component: ModalshellPage,
      //       children: [
      //       { path: 'signin1', loadChildren: '../../pages/auth/signin/signin.module#SigninPageModule'},
      //       { path: 'signup1', loadChildren: '../../pages/auth/signup/signup.module#SignupPageModule'}   
      //     ]
      // }
      

      // added to the Modal Shell as these are only accessed from the Modal, to keep the current page, unchanged (current component not destroyed)
      // Lazy loading works.
      { path: 'signin', loadChildren: '../../pages/auth/signin/signin.module#SigninPageModule', outlet: 'modal'},
      { path: 'signup', loadChildren: '../../pages/auth/signup/signup.module#SignupPageModule', outlet: 'modal'},   
      { path: 'forgotpassword', loadChildren: '../../pages/auth/forgotpassword/forgotpassword.module#ForgotpasswordPageModule', outlet: 'modal'},
      { path: 'changepassword', loadChildren: '../../pages/auth/changepassword/changepassword.module#ChangepasswordPageModule', outlet: 'modal'},   
      { path: 'verifyaccount', loadChildren: '../../pages/auth/verifyaccount/verifyaccount.module#VerifyaccountPageModule', outlet: 'modal'},
      { path: 'updateprofile', loadChildren: '../../pages/auth/updateprofile/updateprofile.module#UpdateprofilePageModule', outlet: 'modal'}   
   
      
  //   { path: 'modalshell', component: ModalshellPage,
  //   children: [
  //     { path: 'signin1', loadChildren: '../../pages/auth/signin/signin.module#SigninPageModule', outlet: 'modal'},
  //     { path: 'signup1', loadChildren: '../../pages/auth/signup/signup.module#SignupPageModule', outlet: 'modal'}   
  //   ]
  // }
  
  // { path: 'modalshell', component: ModalshellPage,
  //   children: [
  //     { path: 'signin1', loadChildren: '../pages/auth/signin/signin.module#SigninPageModule', outlet: 'modal'},
  //     { path: 'signup1', loadChildren: '../pages/auth/signup/signup.module#SignupPageModule', outlet: 'modal'}   
  //   ]
  // }
    
  //   { path: 'modalshell', component: ModalshellPage,
  //   children: [
  //     { path: 'signin1', loadChildren: '../pages/auth/signin/signin.module#SigninPageModule', outlet: 'modal'},
  //     { path: 'signup1', loadChildren: '../pages/auth/signup/signup.module#SignupPageModule', outlet: 'modal'}   
  //   ]
  // }
  
  //       { path: '', component: ModalshellPage, outlet: 'modal', 
  //   children: [
  //     { path: 'signin2', loadChildren: '../pages/auth/signin/signin.module#SigninPageModule', outlet: 'modal'},
  //     { path: 'signup2', loadChildren: '../pages/auth/signup/signup.module#SignupPageModule', outlet: 'modal'}       
  //   ]
  // }
      
      
    ] )
  ],
  declarations: [ModalshellPage]
})
export class ModalshellPageModule {}
