import { Action } from "@ngrx/store";

import { AuthData } from "../../models/authdata.model";

import * as fromApp from "../reducers/auth.reducer"

export enum AuthActionTypes {
  SIGNIN = '[Auth] Signin',
  SIGNINSUCCESS = '[Auth] Signin Success',
  SIGNINFAIL = '[Auth] Signin Fail',  
  SIGNOUT = '[Auth] Signout',  
  SIGNOUTSUCCESS = '[Auth] Signout Success',    
  SIGNUP = '[Auth] Signup',  
  SIGNUPSUCCESS = '[Auth] Signup Success',  
  SIGNUPFAIL = '[Auth] Signup Fail',    
  CHANGEPASSWORD = '[Auth] Change Password',
  FORGOTPASSWORD = '[Auth] Forgot Password',  
  VERIFYACCOUNT = '[Auth] Verify Account',
  VERIFYACCOUNTSUCCESS = '[Auth] Verify Account Success',
  UPDATEPROFILE = '[Auth] Update Profile', 
  UPDATEPROFILESUCCESS = '[Auth] Update Profile Success', 
  CHECKIFLOGGEDON = '[Auth] Check Logged On', 
  CHECKIFLOGGEDONTRUE = '[Auth] Check Logged On True',   
  CHECKIFLOGGEDONFALSE = '[Auth] Check Logged On False',     
}

export class Signin implements Action {
  readonly type = AuthActionTypes.SIGNIN;
  constructor(public payload: {email: string, password: string}) {
  }
}

export class SigninSuccess implements Action {
  readonly type = AuthActionTypes.SIGNINSUCCESS;
  constructor(public payload: {uid: string, email: string, password: string, displayName: string, photoURL: string, emailVerified: boolean, token: string}) {
  }
}

export class SigninFail implements Action {
  readonly type = AuthActionTypes.SIGNINFAIL;
  constructor(public payload: fromApp.AuthState) {
  }
}

export class Signup implements Action {
  readonly type = AuthActionTypes.SIGNUP;
  constructor(public payload: {email: string, password: string, displayName: string, photoURL: string, emailVerified: boolean, token: string}) {
  }
}

export class SignupSuccess implements Action {
  readonly type = AuthActionTypes.SIGNUPSUCCESS;
  constructor(public payload: {uid: string, email: string, password: string, displayName: string, photoURL: string, emailVerified: boolean, token: string}) {
  }
}

export class SignupFail implements Action {
  readonly type = AuthActionTypes.SIGNUPFAIL;
  constructor(public payload: fromApp.AuthState) {
  }
}

export class Signout implements Action {
  readonly type = AuthActionTypes.SIGNOUT;
  constructor(public payload: {email: string}) {   // may be do not send any value
  }
}

export class SignoutSuccess implements Action {
  readonly type = AuthActionTypes.SIGNOUTSUCCESS;
  constructor(public payload: {email: string, password: string, displayName: string, photoURL: string, emailVerified: boolean, token: string}) {   // clears the auth slice
  }
}

export class Changepassword implements Action {
  readonly type = AuthActionTypes.CHANGEPASSWORD;
  constructor(public payload: {email: string, oldpassword: string, password: string}) {
  }
}

export class Forgotpassword implements Action {
  readonly type = AuthActionTypes.FORGOTPASSWORD;
  constructor(public payload: {email: string}) {
  }
}

export class Verifyaccount implements Action {
  readonly type = AuthActionTypes.VERIFYACCOUNT;
  //constructor(public payload: {email: string, password: string, displayName: string, photoURL: string, emailVerified: boolean, token: string}) {
  constructor(public payload: {}) {  // no payload
  }
}

export class VerifyaccountSuccess implements Action {
  readonly type = AuthActionTypes.VERIFYACCOUNTSUCCESS;
   constructor(public payload: {emailVerified: boolean}) {
   }
}


export class Updateprofile implements Action {
  readonly type = AuthActionTypes.UPDATEPROFILE;
  constructor(public payload: {displayName: string, photoURL: string, firstName: string, lastName: string, phone: string}) {
  }
}

export class UpdateprofileSuccess implements Action {
  readonly type = AuthActionTypes.UPDATEPROFILESUCCESS;
  constructor(public payload: {displayName: string, photoURL: string, firstName: string, lastName: string, phone: string}) {
  }
}

export class Checkifloggedon implements Action {
  readonly type = AuthActionTypes.CHECKIFLOGGEDON;
  //constructor(public payload: {displayName: string, photoURL: string}) {
  //}
  // constructor(public payload: {firstName: string, lastName: string, phone: string}) {  // no value sent, the effects will get the custom fields from the custom table /solfeggio-angular/src/models/authdatacustom.model.ts
  // }  
}

export class Checkifloggedontrue implements Action {
  readonly type = AuthActionTypes.CHECKIFLOGGEDONTRUE;
  constructor(public payload: {uid: string, email: string, displayName: string, photoURL: string, emailVerified: boolean, token: string, firstName: string, lastName: string, phone: string}) {
  }
}

export class Checkifloggedonfalse implements Action {
  readonly type = AuthActionTypes.CHECKIFLOGGEDONFALSE;
  constructor(public payload: {uid: string, email: string, displayName: string, password: string, photoURL: string, emailVerified: boolean, token: string, firstName: string, lastName: string, phone: string}) {
  }
}

// export class UpdateprofileFail implements Action {
//   readonly type = AuthActionTypes.UPDATEPROFILEFAIL;
//   //constructor(public payload: fromApp.AuthState) {
//   }
// }

// Union the valid types
export type AuthActions = Signin
     | SigninSuccess
     | SigninFail
     | Signup
     | SignupSuccess
     | SignupFail
     | Signout 
     | SignoutSuccess
     | Changepassword
     | Forgotpassword
     | Verifyaccount
     | VerifyaccountSuccess
     | Updateprofile     
     | UpdateprofileSuccess  
     | Checkifloggedon
     | Checkifloggedontrue
     | Checkifloggedonfalse
