import { createSelector } from '@ngrx/store';
import { PageQuery} from '../actions/main02feature.action';

import * as FROMmain02featureReducer from '../reducers/main02feature.reducer';

import { main02featureUI } from '../../models/main02featureUI.model';

export const selectmain02featureState = state => state.main02feature;

export const getmain02featureall = createSelector(
  selectmain02featureState,
  FROMmain02featureReducer.selectAll
);

export const getmain02featureCount = createSelector(
  selectmain02featureState,
  FROMmain02featureReducer.selectTotal
);

export const getmain02featureallEntities = createSelector(
  selectmain02featureState,
  main02feature=> main02feature.entities
);

export const getsomesampleflag = createSelector(
  selectmain02featureState,
  main02feature=> main02feature.somesampleflag
);

export const selectmain02featurePage = (parentId:string, page:PageQuery) => createSelector(
  getmain02featureall,
  allmain02feature => {
    const start = page.pageIndex * page.pageSize,
          end = start + page.pageSize;
    return allmain02feature
           .filter(main02feature => main02feature.deleted==false && main02feature.parentid == parentId)  // master record may have only one record per user - use relavent key
           .slice(start, end);
}
);

export const getmain02featureOneRecordbyID = (Id:string) => createSelector(
  getmain02featureall,
  allmain02feature => {
    return allmain02feature
           .filter(main02feature => main02feature.id == Id);
}
);

export const selectmain02featureDbSyncPending = createSelector(
  getmain02featureall,
  allmain02feature => {
    return allmain02feature
           .filter(main02feature => main02feature.db_synchronized==false);  // master record may have only one record per user - use relavent key
  }
);

export const selectmain02featureLoading = createSelector(
  selectmain02featureState,
  main02featureState => main02featureState.loading
);

export const getmain02featureCurrentlyActiveRow = createSelector(
  selectmain02featureState,
  main02featureState => main02featureState.currentactiverow
);

export const getmain02featureCurrentlyEditedRow = createSelector(
  selectmain02featureState,
  main02featureState => main02featureState.currenteditrow
);

// export const main02featureLastDatabaseRecordVisible = createSelector(
//   selectmain02featureState,
//   main02featureState => main02featureState.lastrecordvisibleforpagination
// );

export const getmain02featurePageIndexEtc = createSelector(
  selectmain02featureState,
  main02featureState => {return {pageIndex: main02featureState.pageIndex, pageSize: main02featureState.pageSize}}
);
