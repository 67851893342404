import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from "rxjs";
import { main02featureUI } from '../models/main02featureUI.model';
import { catchError, finalize, tap, take } from 'rxjs/operators';
import { AppState } from '../store/reducers';
import { select, Store } from '@ngrx/store';
import { main02featurePageRequested, PageQuery, main02featureSavePaginationVal } from '../store/actions/main02feature.action';
import { selectmain02featurePage, getmain02featureall, getmain02featurePageIndexEtc } from '../store/selectors/main02feature.selectors';

import { UIService } from './ui.services';

import { Injectable} from '@angular/core';

@Injectable()
export class main02featureDataSource implements DataSource<main02featureUI> {
    public main02featureSubject = new BehaviorSubject<main02featureUI[]>([]);
    constructor(private store: Store<AppState>) {
    }

loadmain02feature(parentId:string, page: PageQuery) {
this.store
        .pipe(
         // take(2), 
          select(selectmain02featurePage(parentId, page))
        ).subscribe((main02feature)=> {
             //console.log("[main02feature] Requested page is: ", page);
             if (main02feature.length > 0) { // if data already in the store, use it to populate dataSource of the mat table pagination slice
              this.main02featureSubject.next(main02feature); 
              }
              else {
                this.store.dispatch(new main02featurePageRequested({parentId, page}));  // then after the new records are received are you using this.main02featureSubject.next(main02feature) command?
              }
        }); 
}

connect(collectionViewer: CollectionViewer): Observable<main02featureUI[]> {
        console.log("Connecting data source");
        return this.main02featureSubject.asObservable();
}

disconnect(collectionViewer: CollectionViewer): void {
        this.main02featureSubject.complete();
}

}
