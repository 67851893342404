import { createSelector } from '@ngrx/store';
import { PageQuery} from '../actions/main03feature.action';

import * as FROMmain03featureReducer from '../reducers/main03feature.reducer';

import { main03featureUI } from '../../models/main03featureUI.model';

export const selectmain03featureState = state => state.main03feature;

export const getmain03featureall = createSelector(
  selectmain03featureState,
  FROMmain03featureReducer.selectAll
);

export const getmain03featureCount = createSelector(
  selectmain03featureState,
  FROMmain03featureReducer.selectTotal
);

export const getmain03featureallEntities = createSelector(
  selectmain03featureState,
  main03feature=> main03feature.entities
);

export const getsomesampleflag = createSelector(
  selectmain03featureState,
  main03feature=> main03feature.somesampleflag
);

export const selectmain03featurePage = (parentId:string, page:PageQuery) => createSelector(
  getmain03featureall,
  allmain03feature => {
    const start = page.pageIndex * page.pageSize,
          end = start + page.pageSize;
    return allmain03feature
           .filter(main03feature => main03feature.deleted==false && main03feature.parentid == parentId)  // master record may have only one record per user - use relavent key
           .slice(start, end);
}
);

export const getmain03featureOneRecordbyID = (Id:string) => createSelector(
  getmain03featureall,
  allmain03feature => {
    return allmain03feature
           .filter(main03feature => main03feature.id == Id);
}
);

export const selectmain03featureDbSyncPending = createSelector(
  getmain03featureall,
  allmain03feature => {
    return allmain03feature
           .filter(main03feature => main03feature.db_synchronized==false);  // master record may have only one record per user - use relavent key
  }
);

export const selectmain03featureLoading = createSelector(
  selectmain03featureState,
  main03featureState => main03featureState.loading
);

export const getmain03featureCurrentlyActiveRow = createSelector(
  selectmain03featureState,
  main03featureState => main03featureState.currentactiverow
);

export const getmain03featureCurrentlyEditedRow = createSelector(
  selectmain03featureState,
  main03featureState => main03featureState.currenteditrow
);

// export const main03featureLastDatabaseRecordVisible = createSelector(
//   selectmain03featureState,
//   main03featureState => main03featureState.lastrecordvisibleforpagination
// );

export const getmain03featurePageIndexEtc = createSelector(
  selectmain03featureState,
  main03featureState => {return {pageIndex: main03featureState.pageIndex, pageSize: main03featureState.pageSize}}
);
