import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { main03featureActionTypes, main03featureActions } from "../actions/main03feature.action";
import { main03featureUI } from '../../models/main03featureUI.model';

export interface main03featureState extends EntityState<main03featureUI> {
  allmain03featureLoaded:boolean;
  main03featureCount:number;
  currentactiverow: string;
  currenteditrow: string;
  loading:boolean;
}

export const adapter : EntityAdapter<main03featureUI> =
  createEntityAdapter<main03featureUI>(/*{sortComparer: sortByCourseAndSeqNo}*/);

export const initialmain03featureState: main03featureState = adapter.getInitialState({
  allmain03featureLoaded: false,
  main03featureCount: 0,
  currentactiverow: undefined,
  currenteditrow: undefined,
  loading:false,
});

export function main03featureReducer(state = initialmain03featureState, action:main03featureActions):main03featureState {
  switch (action.type) {
    case main03featureActionTypes.MAIN03FEATUREADDONERECORD : 
      return adapter.addOne(action.payload.main03featureUI, state); 
    case main03featureActionTypes.MAIN03FEATUREUPSERTONERECORD :   
      return adapter.upsertOne(action.payload.main03featureUI, state); 
    case main03featureActionTypes.MAIN03FEATUREUPDATEONERECORD :   
      return adapter.updateOne(action.payload.main03featureUI, state);     
    case main03featureActionTypes.MAIN03FEATUREDELETEONERECORD :   
      return adapter.removeOne(action.payload.id, state);     
    case main03featureActionTypes.MAIN03FEATUREDELETEONERECORD :   
      return {
        ...state
      } 
    case main03featureActionTypes.MAIN03FEATUREPAGEREQUESTED :   
      return {
        ...state
      }
    case main03featureActionTypes.MAIN03FEATUREPAGELOADED :   
      {
      return adapter.addMany(action.payload.main03featureUI_array, {...state, loading:false });
      }
    case main03featureActionTypes.MAIN03FEATURESETACTIVERECORD :   
      return {
        ...state,
        currentactiverow: action.payload.id
      }   
    case main03featureActionTypes.MAIN03FEATURESETEDITRECORD :   
      return {
        ...state,
        currenteditrow: action.payload.id
      }       
    case main03featureActionTypes.MAIN03FEATUREUPDATECOUNT :   
      return {
        ...state,
        main03featureCount: action.payload.count
      } 
    default :
      return state
  }

}

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal

} = adapter.getSelectors();

