import { createSelector } from '@ngrx/store';
import { PageQuery} from '../actions/main01feature.action';

import * as FROMmain01featureReducer from '../reducers/main01feature.reducer';

import { main01featureUI } from '../../models/main01featureUI.model';

export const selectmain01featureState = state => state.main01feature;

export const getmain01featureall = createSelector(
  selectmain01featureState,
  FROMmain01featureReducer.selectAll
);

export const getmain01featureCount = createSelector(
  selectmain01featureState,
  FROMmain01featureReducer.selectTotal
);

export const getmain01featureallEntities = createSelector(
  selectmain01featureState,
  main01feature=> main01feature.entities
);

export const getsomesampleflag = createSelector(
  selectmain01featureState,
  main01feature=> main01feature.somesampleflag
);

export const selectmain01featurePage = (parentId:string, page:PageQuery) => createSelector(
  getmain01featureall,
  allmain01feature => {
    const start = page.pageIndex * page.pageSize,
          end = start + page.pageSize;
    return allmain01feature
           .filter(main01feature => main01feature.deleted==false /*main01feature.parentId == parentId*/)  // master record may have only one record per user - use relavent key
           .slice(start, end);
}
);

export const getmain01featureOneRecordbyID = (Id:string) => createSelector(
  getmain01featureall,
  allmain01feature => {
    return allmain01feature
           .filter(main01feature => main01feature.id == Id);
}
);

export const selectmain01featureDbSyncPending = createSelector(
  getmain01featureall,
  allmain01feature => {
    return allmain01feature
           .filter(main01feature => main01feature.db_synchronized==false);  // master record may have only one record per user - use relavent key
  }
);

export const selectmain01featureLoading = createSelector(
  selectmain01featureState,
  main01featureState => main01featureState.loading
);

export const getmain01featureCurrentlyActiveRow = createSelector(
  selectmain01featureState,
  main01featureState => main01featureState.currentactiverow
);

export const getmain01featureCurrentlyEditedRow = createSelector(
  selectmain01featureState,
  main01featureState => main01featureState.currenteditrow
);

// export const main01featureLastDatabaseRecordVisible = createSelector(
//   selectmain01featureState,
//   main01featureState => main01featureState.lastrecordvisibleforpagination
// );

export const getmain01featurePageIndexEtc = createSelector(
  selectmain01featureState,
  main01featureState => {return {pageIndex: main01featureState.pageIndex, pageSize: main01featureState.pageSize}}
);


