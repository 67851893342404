import {Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import {main02featureUI} from "../models/main02featureUI.model";
import { DBisFirestore } from './db.is.firestore.services';
import { select, Store } from "@ngrx/store";
import { AppState } from '../store/reducers';
import { catchError, finalize, tap, first, map} from 'rxjs/operators';

import { UIService } from './ui.services';

@Injectable()
export class main02featureService {

constructor(private http:HttpClient, public dbisFirestore: DBisFirestore, private store: Store<AppState>, public uiService: UIService) {}
    
findAllmain02feature(parentId:string, pageNumber = 0, pageSize = 3): Observable<main02featureUI[]> {
  
if (!this.uiService.main02featureTheLastDatabaseRecordVisible) {
    this.uiService.main02featureTheLastDatabaseRecordVisible = '-999999999999999999999999999999'
}

if (!parentId || parentId == 'undefined' || parentId == '') {
    parentId = "TOP";
}

return this.dbisFirestore.db
                    .collection("main01featureDB").doc(parentId).collection('main02featureDB', ref => ref.orderBy('lastupdated').startAfter(this.uiService.main02featureTheLastDatabaseRecordVisible).limit(3))
                    .snapshotChanges()
                    .pipe(map(([...docArray]) => {  // solves - ngrx map does not exists on {} issue
                    // throw(new Error());
                    return (docArray as any[]).map(doc => { 
                     const main02featureUI_row = {} as main02featureUI;
                     main02featureUI_row.id = doc.payload.doc.id;
                     main02featureUI_row.parentid = parentId;  // if not saving the parent id in another field on child
                     main02featureUI_row.title =  doc.payload.doc.data()["title"];
                     main02featureUI_row.extra = doc.payload.doc.data()["extra"];
                     main02featureUI_row.deleted = doc.payload.doc.data()["deleted"];  
                     main02featureUI_row.lastupdated = doc.payload.doc.data()["lastupdated"]; 
                     this.uiService.main02featureTheLastDatabaseRecordVisible = doc.payload.doc; 
                    return main02featureUI_row
                             });
                      }))
                   //    .subscribe((data)=> {return data});
     }

}
