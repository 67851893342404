import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AuthActionTypes, Signin, Signup, Signout, Changepassword, Forgotpassword, Verifyaccount, VerifyaccountSuccess, Updateprofile, Checkifloggedon } from '../actions/auth.action';
import { Router } from '@angular/router';

//import { AngularFireAuth } from 'angularfire2/auth';  // moved to services/db.is.firestore.services.ts
//import { AngularFirestore } from 'angularfire2/firestore';  //only required because adding a custom record

import { getauthall } from '../../store/selectors/auth.selectors';

import { UIService } from "../../services/ui.services";

import { AuthDataCustom } from '../../models/authdatacustom.model'

import { select, Store } from "@ngrx/store";
import { tap, map, take } from 'rxjs/operators';

import * as fromAuthAction from "../actions/auth.action";

import { AppState } from '../reducers';  // Note earlier used import * as fromAllReducers from "../reducers"  but is not required
//import * as fromHouseKeepingAction from "../actions/housekeeping.action";  // Leave this here shows updates another state with flag - to access another state and use its actions

import { DBisFirestore } from '../../services/db.is.firestore.services';

@Injectable()
export class AuthEffects {

  constructor(
      private actions$: Actions,
      private router:Router,
      private store: Store<AppState>,
      public uiService: UIService,
      public dBisFirestore: DBisFirestore) {
  }

  @Effect({dispatch:false})   // false (always for me), as we are explicitly dispatching, not returning dispatch- use true when the signin is sucesss we dispatch another action, also for failure
  signin$ = this.actions$.pipe(
    ofType<Signin>(AuthActionTypes.SIGNIN),
    //tap(action => localStorage.setItem("user", JSON.stringify(action.payload.user)))
    tap(action => {
      // call firestore or other API, when success return SigninSuccess or if failed SigninFail
       this.dBisFirestore.Generic_signInWithEmailAndPassword(action.payload.email, action.payload.password)
      .then(data => { 
        //this.afAuth.auth.currentUser.getIdToken().then((token1) => {  //this is to get token also after getting the above information
        this.dBisFirestore.Generic_getIdToken().then((token1) => {  //this is to get token also after getting the above information        
        this.store.dispatch(new fromAuthAction.SigninSuccess({uid: data.user.uid, email: data.user.email, password: '', displayName: data.user.displayName, photoURL: data.user.photoURL, emailVerified: data.user.emailVerified, token: token1})); 
        //this.store.dispatch(new fromHouseKeepingAction.ToggleSomesampleflag({somesampleflag: true}));   // Leave this here shows updates another state with flag
        this.uiService.modalCloseNow.next(true);                
        });
       ////////////yes remove - but leave this line///////////////// this.router.navigateByUrl('/home');  // check this what if the url already had the page and the login was missing  - REMOVE this as when signin page is made modal, we just need to dismiss the model programatically and return to the previous page
        //leave this commented here. this.uiService.usetoastwithok("You are now logged on as: " + data.user.email, 5000);   // this may be duplicate message on user screen if another code with similar messages gets executed because of observables/changes on server

      })
      .catch(error => { 
         this.store.dispatch(new fromAuthAction.SigninFail({uid: '', email: '', password: '', displayName: '', photoURL: '', emailVerified: false, token: '', firstName: '', lastName: '', phone: ''})) 
         this.uiService.usetoastwithok("Unable to sign in with the credentials.", 5000); 
         //this.uiService.modalCloseNow.next(false);  // stay on the login page
      });
    })
  );

  @Effect({dispatch:false})   // false (always for logic used below), as we are explicitly dispatching, not returning dispatch- use true when the signin is sucesss we dispatch another action, also for failure
  signup$ = this.actions$.pipe(
    ofType<Signup>(AuthActionTypes.SIGNUP),
    tap(action => {
      // call firestore or other API, when success return SigninSuccess or if failed SigninFail
       this.dBisFirestore.Generic_createUserWithEmailAndPassword(action.payload.email, action.payload.password)
      .then(data => { 
        // ****** instead of letting firestore generate id, using the uid as id on custom record - will guarentee only one child record
        // here access the collection: authdatacustom for the names and phone - the main key (id) is the uid of the user [done for easy access AND to keep 1-1 relationship]
        this.dBisFirestore.db.collection('authdatacustom').doc(data.user.uid).set({firstName: '', lastName: '', phone: ''}).then((v) => {
                console.log("Added");
                this.store.dispatch(new fromAuthAction.SignupSuccess({uid: data.user.uid, email: action.payload.email, password: action.payload.password, displayName: '', photoURL: '', emailVerified: false, token: ''})); 
                //this.store.dispatch(new fromHouseKeepingAction.ToggleSomesampleflag({somesampleflag: true}));   // updates another state with flag
                ////////////yes remove - but leave this line///////////////// this.router.navigateByUrl('/home');  // check this what if the url already had the page and the login was missing  - REMOVE this as when signup page is made modal, we just need to dismiss the model programatically and return to the previous page
                this.uiService.modalCloseNow.next(true);                
        }).catch((e) => {
          console.log("Error: ", e)
                // update irrespective of if the custom record was added or not
                this.store.dispatch(new fromAuthAction.SignupSuccess({uid: data.user.uid, email: action.payload.email, password: action.payload.password, displayName: '', photoURL: '', emailVerified: false, token: ''})); 
                //this.store.dispatch(new fromHouseKeepingAction.ToggleSomesampleflag({somesampleflag: true}));   // updates another state with flag
                this.uiService.modalCloseNow.next(true);                
        });
      })
      .catch(error => { this.store.dispatch(new fromAuthAction.SignupFail({uid: '', email: '', password: '', displayName: '', photoURL: '', emailVerified: false, token: '', firstName: '', lastName: '', phone: ''})) 
      });
    })
  );
  
  @Effect({dispatch:false})
  signout$ = this.actions$.pipe(
    ofType<Signout>(AuthActionTypes.SIGNOUT),
    tap((action) => {
      // call firestore or other API, when success return SigninSuccess or if failed SigninFail
       this.dBisFirestore.Generic_signOut()
      .then(data => {
        this.store.dispatch(new fromAuthAction.SignoutSuccess({email: '', password: '', displayName: '', photoURL: '', emailVerified: false, token: ''}));         
        this.router.navigate([{ outlets: { primary: ['home']} }], { skipLocationChange: false });   // worked         
        this.uiService.usetoastwithok("Sign out successful", 5000);                    
      })
      .catch(error => { });
      // localStorage.removeItem("user");  //example for local storage
    })
  );

  @Effect({dispatch:false})
  changepassword$ = this.actions$.pipe(
    ofType<Changepassword>(AuthActionTypes.CHANGEPASSWORD),
    tap((action) => {
       this.dBisFirestore.Generic_signInWithEmailAndPassword(action.payload.email, action.payload.oldpassword)   // sometimes in firestore, fresh login is required to change password
      .then(data => { 
                // when verified that the original password is right - attempt to change the password
                this.dBisFirestore.Generic_updatePassword(action.payload.password)
                .then(data => {
                // after the password is changed, there is nothing to update in the store, so no more dispatch actions, just messages
                this.uiService.modalCloseNow.next(true);                
                this.uiService.usetoastwithok("Password has been changed", 10000); })
                .catch(error => {
                this.uiService.usetoastwithok("Unable to change password. " + error, 10000);
                });
                //sample local storage - localStorage.removeItem("user");
                //this.router.navigateByUrl('/Signin');
       })
      .catch(error => { 
        this.uiService.usetoastwithok("Unable to change password, you have entered an incorrect current password. " + error, 10000);});
    })
  );
  
@Effect({dispatch:false})
forgotpassword$ = this.actions$.pipe(
    ofType<Forgotpassword>(AuthActionTypes.FORGOTPASSWORD),
    tap((action) => {
                this.dBisFirestore.Generic_sendPasswordResetEmail(action.payload.email)
                .then(data => {
                // after the password reset request, there is nothing to update in the store, so no more dispatch actions, just messages
                this.uiService.modalCloseNow.next(true);                
                this.uiService.usetoastwithok("Password reset request accepted, please check you email [" + action.payload.email + "] for further instructions.", 10000); 
                })
                .catch(error => {
                this.uiService.usetoastwithok("Unable to reset password. " + error, 10000);
                });
                //this.router.navigateByUrl('/Signin');
       })

 );

@Effect({dispatch:false})
verifyaccount$ = this.actions$.pipe(
    ofType<Verifyaccount>(AuthActionTypes.VERIFYACCOUNT),
    tap((action) => {
                this.dBisFirestore.Generic_sendEmailVerification()
                .then(data => {
                // after the account verify request, there is nothing to update in the store, so no more dispatch actions, just messages  
                this.uiService.usetoastwithok("Verification email sent, please check you email and following instructions.", 20000); 
                //Special case - looks like Firestore does not send the email verified flag as an reactive field (sends displayName, photoURL etc)
                //So after sending email, checking for it couple of time, after few minutes or more.
                //this.afAuth.auth.currentUser.reload();

                // give user few seconds to verify, then try again after few minutes
                 setTimeout(() => {
                     // let us reload 5 seconds before querying the emailVerified flag
                     this.dBisFirestore.Generic_reload();  
                 }, 15*1000);  // 15 seconds
                 
                 setTimeout(() => {
                    this.dBisFirestore.getCurrentUserRefresh(
                        (user) => {
                           if (user) {
                                         //dispatch here
                                            if (user.emailVerified==true) {
                                              this.store.dispatch(new fromAuthAction.VerifyaccountSuccess ({emailVerified: true}));
                                            }
                                }
                            else {
                                    //update nothing if unable to verify email account
                                 }
                }
                        
                        );
                 }, 20*1000); //20 seconds

                // refresh after few minutes
                 setTimeout(() => {
                     // let us reload 5 seconds before querying the emailVerified flag
                     this.dBisFirestore.Generic_reload();  
                 }, 10*60*1000); //10 Minutes
                 
                 setTimeout(() => {
                    this.dBisFirestore.getCurrentUserRefresh(
                        (user) => {
                           if (user) {
                                         //dispatch here
                                            if (user.emailVerified==true) {
                                              this.store.dispatch(new fromAuthAction.VerifyaccountSuccess ({emailVerified: true}));
                                            }
                                }
                            else {
                                    //update nothing if unable to verify email account
                                 }
                }
                        
                        );
                 }, 11*60*1000); //11 minutes

                // dismiss the modal window
                this.uiService.modalCloseNow.next(true);   
                })
                .catch(error => {
                this.uiService.usetoastwithok("Unable to send verify email. " + error, 10000);
                });
       })
 );
 
@Effect({dispatch:false})
updateprofile = this.actions$.pipe(
    ofType<Updateprofile>(AuthActionTypes.UPDATEPROFILE),
    tap((action) => {
                this.dBisFirestore.Generic_updateProfile({displayName: action.payload.displayName, photoURL: action.payload.photoURL})  
                .then(data => {
                //also update the custom auth record, get uid from store
                this.store
                .pipe(
                take(1),
                select(getauthall)
                ).subscribe((data)=> {
                   this.dBisFirestore.db.collection('authdatacustom').doc(data.uid).set({firstName: action.payload.firstName, lastName: action.payload.lastName, phone: action.payload.phone})
                   .then((v) => {
                  // after the account verify request, there is nothing to update in the store, so no more dispatch actions, just messages                  
                  this.store.dispatch(new fromAuthAction.UpdateprofileSuccess ({displayName: action.payload.displayName, photoURL: action.payload.photoURL, firstName: action.payload.firstName, lastName: action.payload.lastName, phone: action.payload.phone})); 
                  this.uiService.modalCloseNow.next(true);                
                  this.uiService.usetoastwithok("Profile has been updated successfully.", 10000); 
                }).catch((e) => {
                 console.log("Error: ", e)
                 // update irrespective of if the custom record was added or not
                 // after the account verify request, there is nothing to update in the store, so no more dispatch actions, just messages                  
                 this.store.dispatch(new fromAuthAction.UpdateprofileSuccess ({displayName: action.payload.displayName, photoURL: action.payload.photoURL, firstName: action.payload.firstName, lastName: action.payload.lastName, phone: action.payload.phone})); 
                 this.uiService.modalCloseNow.next(true);                
                 this.uiService.usetoastwithok("Profile has been updated successfully.", 10000); 
                  });  
                })
                })
                .catch(error => {
                this.uiService.usetoastwithok("Unable to update profile. " + error, 10000);
                });
                //this.router.navigateByUrl('/Signin');
       })

 );
 
@Effect({dispatch:false})  //This session not yet done.
checkifloggedon = this.actions$.pipe(
    ofType<Checkifloggedon>(AuthActionTypes.CHECKIFLOGGEDON),
    tap((action) => {
              //check if user is already logged on.
                //this.afAuth.auth.currentUser.reload(); //without reload, it will not read the user from the database again, and the verified email flag is not updated.
                this.dBisFirestore.Generic_onAuthStateChanged((user) => {
                  if (user) {
                    this.dBisFirestore.Generic_getIdToken().then((token1) => {
                      
                      // ****** Again, instead of letting firestore generate id, using the uid as id on custom record - will guarentee only one child record
                      // here access the collection: authdatacustom for the names and phone - the main key (id) is the uid of the user [done for easy access AND to keep 1-1 relationship]
                      this.dBisFirestore.db.collection("authdatacustom").doc(user.uid).snapshotChanges().pipe(map(data => <AuthDataCustom>data.payload.data()))   //CHECKTHIS
                            .subscribe((row)=>{
                      //this.store.dispatch(new fromAuthAction.Checkifloggedontrue({uid: user.uid, email: user.email, displayName: user.displayName, photoURL: user.photoURL, emailVerified: user.emailVerified, token: token1, firstName: row["firstName"], lastName: row["lastName"], phone: row["phone"]}));
                      this.store.dispatch(new fromAuthAction.Checkifloggedontrue({uid: user.uid, email: user.email, displayName: user.displayName, photoURL: user.photoURL, emailVerified: user.emailVerified, token: token1, firstName: row.firstName, lastName: row.lastName, phone: row.phone}));
                      //this.store.dispatch(new fromHouseKeepingAction.ToggleSomesampleflag({somesampleflag: true}));   // updates another state with flag
                      this.uiService.usetoastwithok("You are signed in as: " + user.email, 5000);   
                     });
                    });
                  }
                  else {
                    this.store.dispatch(new fromAuthAction.Checkifloggedonfalse({uid: '', email: '', password: '', displayName: '', photoURL: '', emailVerified:false, token: '', firstName: '', lastName: '', phone: ''}));
                    //this.store.dispatch(new fromHouseKeepingAction.ToggleSomesampleflag({somesampleflag: false}));   // updates another state with flag                    
                    //this.uiService.usetoastwithok("sign out successful", 5000);      // moved away - do not wish to show this on every page refresh or going to the page first time              
                  }
                  });
       })
 );
 
//   @Effect()
//   init$ = defer(() => {

//     const userData = localStorage.getItem("user");

//     if (userData) {
//       return of(new Signin({user:JSON.parse(userData)}));
//     }
//     else {
//       return <any>of(new Signout());
//     }

//   });

}
