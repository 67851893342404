import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from 'rxjs/internal/operators';
import { ModalshellPage } from '../app/modalshell/modalshell.page';

import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router, ActivatedRoute
} from '@angular/router';

import { UIService } from '../services/ui.services';

@Injectable()
export class AuthGuard implements CanActivate {
  public modalshellPage = ModalshellPage; 
  constructor(private router: Router, private uiService: UIService,) {}

canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> { // any {   //Observable<boolean>
 
  //return concat(of(true));
  
  //console.log(" this.router.routerState.snapshot.url = ", this.router.routerState.snapshot.url);
  
  return this.uiService.auth_getIsLoggedIn$
    .pipe(
        map(res => {
                if (res==true) {
                    return true;
                }
                else {
                    this.uiService.usetoastwithok("You are not yet logged in.", 2000); 
                    this.uiService.onModal(this.modalshellPage, 'signin', state.url);
                    return false;  // this false is important, so the main01feature page is not displayed without the login                  
                }
        })
    )
}
}
