import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from "rxjs";
import { main01featureUI } from '../models/main01featureUI.model';
import { catchError, finalize, tap, take } from 'rxjs/operators';
import { AppState } from '../store/reducers';
import { select, Store } from '@ngrx/store';
import { main01featurePageRequested, PageQuery, main01featureSavePaginationVal } from '../store/actions/main01feature.action';
import { selectmain01featurePage, getmain01featureall, getmain01featurePageIndexEtc } from '../store/selectors/main01feature.selectors';

import { Injectable} from '@angular/core';

@Injectable()
export class main01featureDataSource implements DataSource<main01featureUI> {
    public main01featureSubject = new BehaviorSubject<main01featureUI[]>([]);
    constructor(private store: Store<AppState>) {
    }

loadmain01feature(parentId:string, page: PageQuery) {

this.store
        .pipe(
          take(3),   // workaround TWO
          select(selectmain01featurePage(parentId, page))
        ).subscribe((main01feature)=> {
             //console.log("[main01feature] Requested page is: ", page);
             //this.store.dispatch(new main01featureSavePaginationVal({page: page}));
             if (main01feature.length > 0) { // if data already in the store, use it to populate dataSource of the mat table pagination slice
              this.main01featureSubject.next(main01feature);
              }
              else {
                this.store.dispatch(new main01featurePageRequested({parentId, page}));  // then after the new records are received are you using this.main01featureSubject.next(main01feature) command?
              }
        }); 
}

connect(collectionViewer: CollectionViewer): Observable<main01featureUI[]> {
        console.log("Connecting data source");
        return this.main01featureSubject.asObservable();
}

disconnect(collectionViewer: CollectionViewer): void {
        this.main01featureSubject.complete();
}

}
