import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from "rxjs";
import { main03featureUI } from '../models/main03featureUI.model';
import { catchError, finalize, tap, take } from 'rxjs/operators';
import { AppState } from '../store/reducers';
import { select, Store } from '@ngrx/store';
import { main03featurePageRequested, PageQuery, main03featureSavePaginationVal } from '../store/actions/main03feature.action';
import { selectmain03featurePage, getmain03featureall, getmain03featurePageIndexEtc } from '../store/selectors/main03feature.selectors';

import { UIService } from './ui.services';

import { Injectable} from '@angular/core';

@Injectable()
export class main03featureDataSource implements DataSource<main03featureUI> {
    public main03featureSubject = new BehaviorSubject<main03featureUI[]>([]);
    constructor(private store: Store<AppState>) {
    }

loadmain03feature(grandparentId:string, parentId:string, page: PageQuery) {
this.store
        .pipe(
         // take(2), 
          select(selectmain03featurePage(parentId, page))
        ).subscribe((main03feature)=> {
             //console.log("[main03feature] Requested page is: ", page);
             if (main03feature.length > 0) { // if data already in the store, use it to populate dataSource of the mat table pagination slice
              this.main03featureSubject.next(main03feature); 
              }
              else {
                this.store.dispatch(new main03featurePageRequested({grandparentId, parentId, page}));  // then after the new records are received are you using this.main03featureSubject.next(main03feature) command?
              }
        }); 
}

connect(collectionViewer: CollectionViewer): Observable<main03featureUI[]> {
        console.log("Connecting data source");
        return this.main03featureSubject.asObservable();
}

disconnect(collectionViewer: CollectionViewer): void {
        this.main03featureSubject.complete();
}

}
