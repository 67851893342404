import { Injectable} from '@angular/core';
import { Subject, Subscription} from 'rxjs';

import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';  //only required because adding a custom record


@Injectable()
export class DBisFirestore {
    
constructor(
     private afAuth: AngularFireAuth,
     public db: AngularFirestore
    ) {}

Generic_getIdToken() {
    return this.afAuth.auth.currentUser.getIdToken();
}

Generic_CreateNextUniqueId() {
return this.db.createId()
}

// Move this else where - copy part of code and delete this?
// is this needed - for email verified 20 sec timer function?
getCurrentUserRefresh(fnctn) {

return this.afAuth.auth.onAuthStateChanged(fnctn);

//   this.afAuth.auth.currentUser.reload(); //without reload, it will not read the user from the database again, and the verified email flag is not updated.
//   this.afAuth.auth.onAuthStateChanged((user) => {
//     if (user) {
//       this.currentuser = user;
//       console.log(" user ================================ ", user);
//       console.log(" user.emailVerified ================================ ", user.emailVerified);
//         //update the store, with email verified here<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
//     //   this.onMaintainCustomUserInfoClient();
//     //   this.AuthloadingStateChanged.next(this.currentuser);
//     }
//     else {
//         this.currentuser = null;    
//         this.AuthloadingStateChanged.next(null);
//     }
//     });
    
    
  }


Generic_reload() {
  this.afAuth.auth.currentUser.reload();
 }

Generic_signInWithEmailAndPassword(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
}

Generic_createUserWithEmailAndPassword(email, password) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password);
}

Generic_signOut() {
    return this.afAuth.auth.signOut();
}

Generic_updatePassword(password) {
    return this.afAuth.auth.currentUser.updatePassword(password);
}

Generic_sendPasswordResetEmail(email) {
    return this.afAuth.auth.sendPasswordResetEmail(email);    
}

Generic_sendEmailVerification() {
    return this.afAuth.auth.currentUser.sendEmailVerification();
}

Generic_updateProfile(values) {
   return this.afAuth.auth.currentUser.updateProfile(values); 
}

Generic_onAuthStateChanged(callbackFnctn) {
    return this.afAuth.auth.onAuthStateChanged(callbackFnctn);
}

// Generic_onLoadTestData() {
// //console.log("Insert documents");
// var id: string;
// id = this.Generic_CreateNextUniqueId();
// this.db.collection('main01feature').doc(id).set({title: 'title-'+id}); 
// }
     
}