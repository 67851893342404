import { Action } from "@ngrx/store";
import { Update } from '@ngrx/entity';

import { main03featureUI } from '../../models/main03featureUI.model';

export enum main03featureActionTypes {
  MAIN03FEATUREADDONERECORD = '[main03feature] Add one Record',
  MAIN03FEATUREUPDATEONERECORD = '[main03feature] Update one Record',  
  MAIN03FEATUREDELETEONERECORD = '[main03feature] Delete one Record', 
  MAIN03FEATUREPAGEREQUESTED = '[main03feature] Page Requested', 
  MAIN03FEATUREPAGELOADED = '[main03feature] Page Loaded From DB', 
  MAIN03FEATUREPAGECANCELLED = '[main03feature] Page Request Cancelled', 
  MAIN03FEATUREUPSERTONERECORD = '[main03feature] Upsert one Record',
  MAIN03FEATURESETACTIVERECORD = '[main03feature] Set active Record',  
  MAIN03FEATURESETEDITRECORD = '[main03feature] Set edit Record', 
  MAIN03FEATUREUPDATECOUNT = '[main03feature] Update record Count', 
  MAIN03FEATURESAVEPAGINATIONVAL = '[main03feature] Save PageIndex, etc for pagination', 
}

export interface PageQuery {
  pageIndex: number;
  pageSize:number;
}

export class main03featurePageRequested implements Action {
  readonly type = main03featureActionTypes.MAIN03FEATUREPAGEREQUESTED;
  constructor(public payload: {grandparentId:string, parentId:string, page:PageQuery}) {
  }
}

export class main03featureSavePaginationVal implements Action {
  readonly type = main03featureActionTypes.MAIN03FEATURESAVEPAGINATIONVAL;
  constructor(public payload: {page:PageQuery}) {
  }
}

export class main03featurePageLoaded implements Action {
  readonly type = main03featureActionTypes.MAIN03FEATUREPAGELOADED;
  constructor(public payload: {main03featureUI_array: main03featureUI[]}) {
  }
}

export class main03featurePageCancelled implements Action {
  readonly type = main03featureActionTypes.MAIN03FEATUREPAGECANCELLED;
  constructor(public payload: {parentId:string, page:PageQuery}) {
  }
}

export class main03featureAddOneRecord implements Action {
  readonly type = main03featureActionTypes.MAIN03FEATUREADDONERECORD;
  constructor(public payload: {main03featureUI: main03featureUI}) {
  }
}

export class main03featureUpdateOneRecord implements Action {
  readonly type = main03featureActionTypes.MAIN03FEATUREUPDATEONERECORD;
  constructor(public payload: {main03featureUI: Update<main03featureUI>}) {
  }
}

export class main03featureDeleteOneRecord implements Action {
  readonly type = main03featureActionTypes.MAIN03FEATUREDELETEONERECORD;
  constructor(public payload: {id: string}) {
  }
}

export class main03featureUpsertOneRecord implements Action {
  readonly type = main03featureActionTypes.MAIN03FEATUREUPSERTONERECORD;
  constructor(public payload: {main03featureUI: main03featureUI}) {
  }
}
  
export class main03featureSetActiveRecord implements Action {
  readonly type = main03featureActionTypes. MAIN03FEATURESETACTIVERECORD;
   constructor(public payload: {id: string}) {
  }
}

export class main03featureSetEditRecord implements Action {
  readonly type = main03featureActionTypes. MAIN03FEATURESETEDITRECORD;
   constructor(public payload: {id: string}) {
  }
}

export class main03featureUpdateCount implements Action {
  readonly type = main03featureActionTypes.MAIN03FEATUREUPDATECOUNT;
   constructor(public payload: {count: number}) {
  }
}

// Union the valid types
export type main03featureActions = 
    main03featureAddOneRecord
  | main03featureUpdateOneRecord  
  | main03featureDeleteOneRecord
  | main03featurePageRequested
  | main03featurePageLoaded
  | main03featurePageCancelled  
  | main03featureUpsertOneRecord
  | main03featureSetActiveRecord
  | main03featureSetEditRecord  
  | main03featureUpdateCount
  | main03featureSavePaginationVal
  
