import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { main02featureActionTypes, main02featureActions } from "../actions/main02feature.action";
import { main02featureUI } from '../../models/main02featureUI.model';

export interface main02featureState extends EntityState<main02featureUI> {
  allmain02featureLoaded:boolean;
  main02featureCount:number;
  currentactiverow: string;
  currenteditrow: string;
  loading:boolean;
}

export const adapter : EntityAdapter<main02featureUI> =
  createEntityAdapter<main02featureUI>(/*{sortComparer: sortByCourseAndSeqNo}*/);

export const initialmain02featureState: main02featureState = adapter.getInitialState({
  allmain02featureLoaded: false,
  main02featureCount: 0,
  currentactiverow: undefined,
  currenteditrow: undefined,
  loading:false,
});

export function main02featureReducer(state = initialmain02featureState, action:main02featureActions):main02featureState {
  switch (action.type) {
    case main02featureActionTypes.MAIN02FEATUREADDONERECORD : 
      return adapter.addOne(action.payload.main02featureUI, state); 
    case main02featureActionTypes.MAIN02FEATUREUPSERTONERECORD :   
      return adapter.upsertOne(action.payload.main02featureUI, state); 
    case main02featureActionTypes.MAIN02FEATUREUPDATEONERECORD :   
      return adapter.updateOne(action.payload.main02featureUI, state);     
    case main02featureActionTypes.MAIN02FEATUREDELETEONERECORD :   
      return adapter.removeOne(action.payload.id, state);     
    case main02featureActionTypes.MAIN02FEATUREDELETEONERECORD :   
      return {
        ...state
      } 
    case main02featureActionTypes.MAIN02FEATUREPAGEREQUESTED :   
      return {
        ...state
      }
    case main02featureActionTypes.MAIN02FEATUREPAGELOADED :   
      {
      return adapter.addMany(action.payload.main02featureUI_array, {...state, loading:false });
      }
    case main02featureActionTypes.MAIN02FEATURESETACTIVERECORD :   
      return {
        ...state,
        currentactiverow: action.payload.id
      }   
    case main02featureActionTypes.MAIN02FEATURESETEDITRECORD :   
      return {
        ...state,
        currenteditrow: action.payload.id
      }       
    case main02featureActionTypes.MAIN02FEATUREUPDATECOUNT :   
      return {
        ...state,
        main02featureCount: action.payload.count
      } 
    default :
      return state
  }

}

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal

} = adapter.getSelectors();

