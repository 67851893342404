import { HouseKeepingActions, HouseKeepingActionTypes } from "../actions/housekeeping.action";
import { select, Store, createFeatureSelector, createSelector } from '@ngrx/store';

export interface HouseKeepingState {
  somesampleflag: boolean;
}

const initialState: HouseKeepingState = {
  somesampleflag: false
};

export function HouseKeepingReducer(state = initialState, action:HouseKeepingActions):HouseKeepingState {
  switch (action.type) {
    case HouseKeepingActionTypes.ToggleSomesampleflag :
      //  console.log("housekeeping - stateeeeeeeeeeeeeeeeeeeeeeeeeee = ", state);
      //  console.log("housekeeping - action.typeeeeeeeeeeeeeeee = ", action.type);
     //   console.log("housekeeping - action.payloadddddddddddddddddddddddddddddd = ", action.payload);
      return {
        ...state,
        somesampleflag: action.payload.somesampleflag
      }      
    default :
   // console.log("In default");
      return state
  }

}